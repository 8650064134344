(function($) {
  $(window).load(function() {
    if(!document.getElementById("google-map")) {
      return false;
    }

    var locations = [];
    var bound = new google.maps.LatLngBounds();
    // var address = $(".node--contact .field--body").text().trim().replace(/(\r\n|\n|\r)/gm," ");
    var address = "Albert-1-laan 159 Nieuwpoort";
    getLatLong(address);

    function getLatLong(address) {
      var geo = new google.maps.Geocoder;
      geo.geocode({
        'address': address,
        region: 'no'
      }, function(results, status) {
        if (status.toLowerCase() == 'ok') {
             // locations[] = results[0].geometry.location;
             locations.push(results[0].geometry.location);
             initialize();
           } else {
            // alert("problem");
          }
        });
    }

    function initialize() {
      var gmarkers = [];
      var map = null;
      var infowindow = null;

      var styles = [
      {
        featureType: "administrative",
        elementType: "all",
        stylers: [
        { saturation: -99 }
        ]
      },{
        featureType: "landscape",
        elementType: "all",
        stylers: [
        { saturation: -99 }
        ]
      },{
        featureType: "poi",
        elementType: "all",
        stylers: [
        { saturation: -99 }
        ]
      },{
        featureType: "road",
        elementType: "all",
        stylers: [
        { saturation: -99 }
        ]
      },{
        featureType: "transit",
        elementType: "all",
        stylers: [
        { saturation: -99 }
        ]
      },{
        featureType: "water",
        elementType: "all",
        stylers: [
        { saturation: -99 }
        ]
      }
      ];

      for (var i = 0; i < locations.length; i++) {
        var latLang = convertLatLong(locations[i]);
        bound.extend(new google.maps.LatLng(latLang.lat, latLang.long));
      }

      var mapOptions = {
        center: bound.getCenter(),
        zoom: 15,
        panControl: false,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        overviewMapControl: false,
        scrollwheel: false,
        draggable: false,
        styles: styles
      };

      map = new google.maps.Map(document.getElementById("google-map"), mapOptions);

      google.maps.event.addListener(map, 'click', function() {
        infowindow.close();
      });
      function convertLatLong(location) {
        location = location.toString().replace('(', '').replace(')', '').replace(' ', '');
        location = location.split(',');
        var latLong = {lat: location[0], long: location[1]};
        return latLong;
      }
      function setMarkers(locations) {
        for (var i = 0; i < locations.length; i++) {
          var location = locations[i];
          var latLong = convertLatLong(location);
          var myLatLng = new google.maps.LatLng(latLong.lat, latLong.long);
          var marker = new google.maps.Marker({
            position: myLatLng,
            animation: google.maps.Animation.DROP,
            map: map,
            title: location[0]
        });

        // Open google maps at coordinates on marker click
        google.maps.event.addListener(marker, "click", function () {
          window.open("https://maps.google.com/maps?daddr=" + convertLatLong(location).lat + "," + convertLatLong(location).long + "&hl=nl");
        });
        gmarkers.push(marker);
      }
    }
    setMarkers(locations);
    }
  });
})(jQuery);