var _LOGISID = 1281;
// Wijzig in uw gewenste taal (en, fr, nl, de, es, it)
var _TAAL = "en";
(function ($) {
  Drupal.behaviors.cubilisBehavior = {
    attach: function (context, settings) {
      $("body", context).once("cubilis", function () {
        _TAAL = jQuery(".lang-switch li.active").text().trim();
        $("#startdate").datepicker({
          dateFormat: "dd-mm-yy",
          buttonImage: Drupal.settings.pathToTheme + "/img/calendar.svg",
          showOn: "both",
          buttonImageOnly: true,
          minDate: 0,
          altField: "#arrival",
          altFormat: "yy-mm-dd",
          onSelect: function () {
            $("#startdate").val(this.value);
            console.log(this.value);
          },
        });
        $("#enddate").datepicker({
          dateFormat: "dd-mm-yy",
          buttonImage: Drupal.settings.pathToTheme + "/img/calendar.svg",
          showOn: "both",
          buttonImageOnly: true,
          minDate: 1,
          altField: "#departure",
          altFormat: "yy-mm-dd",
        });
        var today = new Date();
        $("#startdate").datepicker("setDate", new Date());
        var tomorrow = today.add(1).days();
        $("#enddate").datepicker("setDate", tomorrow);

        $("#startdate").change(function () {
          var d = Date.parseExact($(this).val(), "dd-MM-yyyy");
          d = d.add(1).days();
          $("#enddate").datepicker("option", "minDate", d);
        });
      });
    },
  };

  Drupal.behaviors.camBehavior = {
    attach: function (context, settings) {
      $(".farocam_wrapper a", context).once("cam", function () {
        $(this).fancybox();
      });
    },
  };

  Drupal.behaviors.fanyboxBehavior = {
    attach: function (context, settings) {
      $("body", context).once("fanybox", function () {
        $(".fancybox").fancybox();
      });
    },
  };

  Drupal.behaviors.bannerBehavior = {
    attach: function (context, settings) {
      $(".banner", context).once("banner", function () {
        var $banner = $(this);
        var $images = $banner.find(".field--banner-image");
        var pageCount = $images.length;
        var $pager = $('<div class="banner__pager"></div>');
        var $pages = $();
        $images.each(function (index) {
          var src = $(this).find("img").attr("src");
          $(this).css("background-image", "url(" + src + ")");
          $pages = $pages.add(
            $('<a class="banner__page">' + (index + 1) + "</a>")
          );
        });
        $pager.append($pages);
        $banner.append($pager);

        $images.eq(0).siblings().fadeOut(0);
        $pages.eq(0).addClass("active");
        var currentIndex = 0;

        var slideInterval;
        resetSlideInterval();

        function resetSlideInterval() {
          if (slideInterval) clearInterval(slideInterval);
          slideInterval = setInterval(nextSlide, 5000);
        }

        $pages.click(function (e) {
          e.preventDefault();
          resetSlideInterval();
          currentIndex = $(this).index();
          slide();
        });

        function nextSlide() {
          currentIndex++;
          slide();
        }

        function slide() {
          if (currentIndex >= pageCount) currentIndex = 0;
          $images.fadeOut("300");
          $images.eq(currentIndex).stop().fadeIn("300");
          $pages
            .eq(currentIndex)
            .addClass("active")
            .siblings()
            .removeClass("active");
        }
      });
    },
  };

  Drupal.behaviors.languageSwitcherBehavior = {
    attach: function (context, settings) {
      $(".language-switcher-locale-url", context).once(
        "languageSwitcher",
        function () {
          var $languageSwitcher = $(this);
          $languageSwitcher.find("li.active").prependTo($languageSwitcher);
        }
      );
    },
  };

  Drupal.behaviors.uitInNieuwpoortBehavior = {
    attach: function (context, settings) {
      $("body", context).once("uitInNieuwpoort", function () {
        $("body").load(
          "http://www.uitinvlaanderen.be/agenda/doen/nieuwpoort #block-system-main"
        );
      });
    },
  };

  Drupal.behaviors.menuToggleBehavior = {
    attach: function (context, settings) {
      $(".main-menu", context).once("menuToggle", function () {
        var $toggle = $('<a href="#" class="menu-toggle">Menu</a>');
        $toggle.insertBefore($(this));
        var $menu = $(this);
        $menu.slideUp(0);
        $toggle.click(function (e) {
          e.preventDefault();
          $menu.slideToggle(300);
        });
      });
    },
  };

  Drupal.behaviors.roomBehavior = {
    attach: function (context, settings) {
      $(".node--room--full", context).once("room", function () {
        var $bookingButton = $(this).find(".read-more");
        var $imagesWrapper = $(this).find(".field-photo-wrp");
        $bookingButton.appendTo($imagesWrapper);
      });
    },
  };

  Drupal.behaviors.matchHeightBehavior = {
    attach: function (context, settings) {
      $("body", context).once("matchHeight", function () {
        $(this).imagesLoaded(function () {
          // $('.l-region--postscript > .panel-pane').matchHeight();
        });
      });
    },
  };
})(jQuery);
